import React from "react"
import "./services.scss"
import Service from "./service/service"

export default function Services() {
  return (
    <section className="services">
      <div className="services__wrapper">
        <h2 className="services__heading">Què oferim</h2>

        <ul className="services__list">
          <Service title="Taula individual per a cada usuari" icon="desk" />
          <Service title="Iternet d'alta velocitat" icon="wifi" />
          <Service title="Impressora làser a color i escàner" icon="print" />
          <Service title="Zona office i descans" icon="couch" />
          <Service title="Taquilles per als usuaris" icon="locker" />
          <Service title="Recepció de correu i paquets" icon="delivery" />
          <Service title="Espai de reunions" icon="table" />
        </ul>
        <aside className="other-services">
          <h3 className="other-services__heading">Necessites un espai per a la teva reunió o esdeveniment?</h3>
          <div className="other-services__text">
            <p>Oferim també lloguer d'espai de reunions i espai per a esdeveniments.</p>
            <p><a href="#contact">Contacta amb nosaltres</a> i descobreix els horaris i preus.</p>
          </div>
        </aside>
      </div>
    </section>
  )
}
