import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./location.scss"

export default function Location() {
  const data = useStaticQuery(graphql`
    query {
      mapDesktop: file(relativePath: { eq: "location/map.png" }) {
        childImageSharp {
          fluid(maxWidth: 798) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mapMobile: file(relativePath: { eq: "location/map-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    data.mapMobile.childImageSharp.fluid,
    {
      ...data.mapDesktop.childImageSharp.fluid,
      media: `(min-width: 550px)`,
    },
  ]

  return (
    <section className="location">
      <div className="location__wrapper">
        <h2 className="location__heading">On som</h2>
        <address className="location__address">
          Carrer Carretera Vella 18<br />
          08110 Montcada i Reixac
        </address>
        <figure className="location__map">
          <a href="https://maps.google.com/maps?ll=41.483145,2.185615&z=17&t=m&hl=ca&gl=ES&mapclient=embed&cid=15280608857977504668" target="_blank" rel="noreferrer"><Img fluid={sources} alt="Localització del Coworking Montcada al mapa" /></a>
        </figure>
      </div>
    </section>
  )
}
