import React from "react"
import { Helmet } from "react-helmet";
import Header from "../components/header/header"
import Claim from "../components/claim/claim"
import Intro from "../components/intro/intro"
import Services from "../components/services/services";
import Pics from "../components/pics/pics";
import Prices from "../components/prices/prices";
import Location from "../components/location/location";
import Footer from "../components/footer/footer";
import Contact from "../components/contact/contact";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="language" content="ca" />
        <link rel="canonical" href="https://coworkingmontcada.com/" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="icon" href="/assets/favicon.ico" />
        <title> Montcada Coworking | Un nou espai de treball a Montcada i Reixac </title>
        <meta name="title" content="Montcada Coworking | Un nou espai de treball a Montcada i Reixac" />
        <meta name="description" content="Montcada coworking és un espai creat per aquelles persones que treballen en remot però que prefereixen disposar d'un lloc de treball fora del seu entorn personal per a treballar. És també adient per a petits negocis o professionals independents que vulguin disposar d'un lloc de treball a uns costos controlats." />
        <meta name="abstract" content="Montcada coworking és un espai creat per aquelles persones que treballen en remot però que prefereixen disposar d'un lloc de treball fora del seu entorn personal per a treballar. És també adient per a petits negocis o professionals independents que vulguin disposar d'un lloc de treball a uns costos controlats." />
        <meta name="keywords" content="coworking Montcada i Reixac, coworking, oficina compartida Montcada i Reixac, oficina compartida" />
        <meta name="og:title" content="Montcada Coworking" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://coworkingmontcada.com/" />
        <meta name="og:image" content="https://coworkingmontcada.com/assets/logo.svg" />
        <meta name="og:site_name" content="Montcada Coworking" />
        <meta name="og:description" content="Montcada coworking és un espai creat per aquelles persones que treballen en remot però que prefereixen disposar d'un lloc de treball fora del seu entorn personal per a treballar. És també adient per a petits negocis o professionals independents que vulguin disposar d'un lloc de treball a uns costos controlats." />
        <meta name="og:email" content="info@coworkingmontcada.com"/>
        <meta name="og:phone_number" content="+34 636 154 006"/>
        <meta name="og:latitude" content="41.483145"/>
        <meta name="og:longitude" content="2.185615"/>
        <meta name="og:street-address" content="Carrer Carretera Vella 18"/>
        <meta name="og:locality" content="Montcada i Reixac"/>
        <meta name="og:region" content="Barcelona"/>
        <meta name="og:postal-code" content="08110"/>
        <meta name="og:country-name" content="Spain"/>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://coworkingmontcada.com/",
              "telephone": "+34 636 154 006",
              "email": "info@coworkingmontcada.com",
              "name": "Montcada Coworking",
              "description": "Montcada coworking és un espai creat per aquelles persones que treballen en remot però que prefereixen disposar d'un lloc de treball fora del seu entorn personal per a treballar. És també adient per a petits negocis o professionals independents que vulguin disposar d'un lloc de treball a uns costos controlats.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Carrer Carretera Vella 18",
                "addressLocality": "Montcada i Reixac",
                "addressRegion": "Barcelona",
                "postalCode": "08110"
              },
              "sameAs": ]
                "https://www.instagram.com/coworkingmontcada/"
              ],
              "founder": {
                "@type": "Person",
                "name": "Ramon Vilar Gavaldà"
              },
              "logo": "/assets/logo.svg"
              "knowsLanguage": "Catalan, Spanish, English",
            }
          `}
        </script>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <main>
        <Claim />
        <Intro />
        <Services />
        <Pics />
        <Prices />
        <Location />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
