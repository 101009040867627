import React from "react"
import "./link-button.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function LinkButton({link, text}) {
  return (
    <div className="linkbutton">
      <AnchorLink to={link} className="linkbutton__link">{text}</AnchorLink>
    </div>
  )
}
