import React from "react"
import "./intro.scss"

export default function Intro() {
  return (
    <section className="intro">
      <div className="intro__wrapper">
        <h2 className="intro__heading"><span className="intro__heading-first">Llum,</span> <span className="intro__heading-second">Espai,</span> <span className="intro__heading-third">Comunitat,</span> <span className="intro__heading-forth">Tranquil·litat.</span></h2>
        <div className="intro__text">
          <p>Montcada coworking és un espai creat per aquelles persones que treballen en remot, però que prefereixen disposar d'un lloc de treball compartit fora del seu entorn personal. És també adient per a petits negocis o professionals independents que vulguin disposar d'un lloc de treball propi, independent i a uns costos controlats.</p>
          <p>Localitzat al centre de Montcada i Reixac, en un espai on predomina la llum natural i al costat del transport públic. Disposa de totes les facilitats necessàries per a poder treballar còmodament des d'un espai lliure, acollidor i tranquil.</p>
        </div>
      </div>
    </section>
  )
}
