import React from "react"

export default function PriceItem({title, price, data}) {
  return (
    <article className="price-item">
      <h3 className="price-item__title">{title}</h3>
      <div className="price-item__heading"><span className="price-item__price">{price}</span><span className="price-item__period">/mes</span></div>
      <div className="price-item__taxes">(+ IVA)</div>
      <ul className="price-item__data">
        {data.map(item => <li key={item.toString()}>{item}</li>)}
      </ul>
    </article>
  )
}
