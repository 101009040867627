import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./claim.scss"
import LinkButton from "../link-button/link-button"

export default function Claim() {
  const data = useStaticQuery(graphql`
    query {
      station: file(relativePath: { eq: "claim/train-station.png" }) {
        childImageSharp {
          fluid(maxWidth: 698) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="claim">
      <div className="claim__wrapper">
        <h2 className="claim__title">Un espai en creació, fet per a compartir.</h2>
        <figure className="claim__image">
          <Img fluid={data.station.childImageSharp.fluid} alt="Estació de Montcada i Reixac, a 2 min a peu de Coworking Montcada" />
        </figure>
        <p className="claim__description">Localitzat al centre de Montcada i Reixac, amb llum natural i al costat del transport públic.</p>
        <LinkButton text="Veure preus" link="/#prices" />
      </div>
    </section>
  )
}
