import React from "react"
import Logo from "./logo.inline.svg"
import "./header.scss"

export default function Header() {
  return (
    <header className="header">
      <div className="header__wrapper">
        <Logo className="logo"/>
      </div>
    </header>
  )
}
