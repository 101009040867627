import React from "react"
import "./contact.scss"

export default function Contact() {
  return (
    <section className="contact" id="contact">
      <div className="contact__wrapper">
        <h2 className="contact__heading">Interessat? Posa't en contacte</h2>
        <div class="contact__form">
          <p>Et resoldrem qualsevol dubte que tinguis sobre els nostres serveis i preus.</p>
          <form action="https://formcarry.com/s/Ubb74hYTc5ln" method="POST" acceptCharset="UTF-8" className="form">
            <input type="hidden" name="_gotcha" />
            <div className="form__line">
              <label htmlFor="name" className="form__label">Nom</label>
              <input type="text" name="name" id="name" className="form__item" />
            </div>
            <div className="form__line">
              <label htmlFor="email" className="form__label">E-mail</label>
              <input type="email" name="email" id="email" className="form__item" />
            </div>
            <div className="form__line">
              <label htmlFor="message" className="form__label">Missatge</label>
              <textarea name="message" id="message" rows="5" className="form__item" />
            </div>
            <div className="form__line">
              <input type="submit" value="Envia" className="form__submit" />
            </div>
          </form>
        </div>
        <div className="contact__description">
          <a href="mailto:info@coworkingmontcada.com">info@coworkingmontcada.com</a><br />
          <a href="tel:+34636154006">+34 636 154 006</a>
        </div>
      </div>
    </section>
  )
}
