import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "./pics.scss";

export default function Pics() {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "pics/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 763) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      image2: file(relativePath: { eq: "pics/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 763) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
    </>
    /*<section className="pics">
      <figure className="pics__image">
        <Img fluid={data.image1.childImageSharp.fluid} alt="Imatge 1" />
      </figure>
      <figure className="pics__image">
        <Img fluid={data.image2.childImageSharp.fluid} alt="Imatge 1" />
      </figure>
    </section>*/
  );
}
