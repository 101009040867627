import React from "react"
import "./prices.scss"
import LinkButton from "../link-button/link-button"
import PriceItem from "./price-item/price-item"

export default function Prices() {
  return (
    <section className="prices" id="prices">
      <div className="prices__wrapper">
        <h2 className="prices__heading">Els nostres preus</h2>
        <div className="prices__items">
          <PriceItem title="Domiciliació fiscal" price="30€" data={['Adreça d\'empresa', 'Recollida de paquets i correspondència', 'Notificació directa a la recepció']} />
          <PriceItem title="Teletreball" price="80€" data={['10 dies en un mes', 'Taula individual', 'Accés a l\'espai de reunions (segons disponibilitat)']} />
          <PriceItem title="Full time" price="125€" data={['Accés sense restriccions', 'Taula individual', 'Accés a l\'espai de reunions', 'Domiciliació fiscal']} />
        </div>
        <LinkButton text="Vull saber-ne més" link="/#contact" />
      </div>
    </section>
  )
}
