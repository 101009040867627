import React from "react"
import "./footer.scss"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__wrapper">Montcada coworking - Tots els drets reservats</div>
    </footer>
  )
}
